import map from 'lodash/map';

import { TeamUUID, FetchTeamsCacheKey } from '../../../../../teamsTypes';
import {
  InviteNewUsersToCompanyFormData,
  InviteNewUsersToCompanyFormFields
} from '../../InviteNewUsersToCompanyForm.types';

import { CREATE_TEAM_INVITATIONS_QUERY } from '../../../../../../teamInvitations/queries/createTeamInvitations.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateTeamInvitations } from '../../../../../../teamInvitations/hooks/useCreateTeamInvitations';

import { inviteNewUsersToCompanyFormValidationSchema } from './useInviteNewUsersToCompanyForm.schema';

import { calcTeamInvitationExpiryDateFromNow } from '../../../../../../teamInvitations/utils/calcTeamInvitationExpiryDateFromNow';

interface UseInviteNewUsersToCompanyFormOptions {
  companyUuid: TeamUUID;
  cacheKeys?: FetchTeamsCacheKey[];
  afterSubmit?: () => void;
}

const defaultValues = {
  emails: ['']
};

function useInviteNewUsersToCompanyForm({
  companyUuid,
  cacheKeys,
  afterSubmit
}: UseInviteNewUsersToCompanyFormOptions) {
  const { register, handleSubmitReactHookForm, control, resetForm, watch } =
    useReactHookForm<InviteNewUsersToCompanyFormData>({
      defaultValues,
      isModalForm: true,
      schema: inviteNewUsersToCompanyFormValidationSchema
    });

  const watchEmails = watch(InviteNewUsersToCompanyFormFields.EMAILS);

  const {
    createTeamInvitationsReset,
    createTeamInvitationsLoading,
    createTeamInvitationsErrorMessage,
    createTeamInvitations
  } = useCreateTeamInvitations({
    query: CREATE_TEAM_INVITATIONS_QUERY,
    cacheKeys
  });

  return {
    control,
    watchEmails,
    resetInviteNewUsersToCompanyForm: resetForm,
    createTeamInvitationsReset,
    createTeamInvitationsErrorMessage,
    createTeamInvitationsLoading,
    handleInviteNewUsersToCompany: handleSubmitReactHookForm({
      onSubmit: async (data: InviteNewUsersToCompanyFormData) =>
        createTeamInvitations({
          emails: map(data.emails, (email) => email.trim()),
          teamId: companyUuid,
          expiredAt: calcTeamInvitationExpiryDateFromNow()
        }).then(() => {
          afterSubmit?.();
        })
    }),
    registerFields: {
      registerEmails: register(InviteNewUsersToCompanyFormFields.EMAILS)
    }
  };
}

export default useInviteNewUsersToCompanyForm;
